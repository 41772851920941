import axios from "axios";
import version from '@/services/version'

import store from '@/store/index';

const endpoint = process.env.VUE_APP_EKOAPI + '/api/v2/';


export default  {

  checkerror: (error) => {
    // forbiden
    console.log('check error');
    if (error.response.status && (error.response.status == 403 || error.response.status == 401)) {
      store.dispatch('login/logout');
      return true;
    }

    return false;

  },
  get: async function({url}){

    let self = this

    const config = {
      headers: {
        'APPVERSION': version.version,
        'TOKEN' : store.getters['login/getToken']
      }
    };
    console.log('axios get', url, config);

    return new Promise(function(resolve, reject){
      axios.get(endpoint + url, config).then(response => {
        resolve(response.data)
      })
        .catch(error => {
          if (error.response) {

            self.checkerror(error);
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
          }
          console.log('Axios GET error', error);
          reject(error)
          // this.errored = true
        })
    })

  },
  post: async function({url, params}){

    let self = this

    const config = {
      headers: {
        'APPVERSION': version.version,
        'TOKEN' : store.getters['login/getToken']
      }
    };
    console.log('axios post', url, params, config);

    return new Promise(function(resolve, reject){
        axios.post(endpoint + url, params, config).then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response) {

            self.checkerror(error);
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
          }
          console.log('Axios Post error', error);
          reject(error)
          // this.errored = true
        })
    })

  },
  postFile: async function({url, params}){

    let self = this

    const config = {
      headers: {
        'APPVERSION': version.version,
        'TOKEN' : store.getters['login/getToken'],
        'Content-Type': 'multipart/form-data'
      }
    };
    console.log('axios post', url, params, config);

    return new Promise(function(resolve, reject){
        axios.post(endpoint + url, params, config).then(response => {
          resolve(response.data)
        })
        .catch(error => {
          if (error.response) {

            self.checkerror(error);
            /*
             * The request was made and the server responded with a
             * status code that falls out of the range of 2xx
             */
            console.log(error.response.data);
            console.log(error.response.status);
            console.log(error.response.headers);
          } else if (error.request) {
            /*
             * The request was made but no response was received, `error.request`
             * is an instance of XMLHttpRequest in the browser and an instance
             * of http.ClientRequest in Node.js
             */
            console.log(error.request);
          } else {
            // Something happened in setting up the request and triggered an Error
            console.log('Error', error.message);
          }
          console.log('Axios Post error', error);
          reject(error)
          // this.errored = true
        })
    })

  }

}