<template>
	<div id="page-container"
		 class="sidebar-o sidebar-mini enable-page-overlay side-scroll page-header-fixed page-header-dark page-footer-dark"
		 :class="{'sidebar-o-xs':showmenu}">
		<nav id="sidebar" aria-label="Main Navigation">
			<!-- Side Header -->
			<div class="bg-header-dark">
				<div class="content-header bg-white-10">
					<!-- Logo -->
					<a class="font-w600 smini-visible font-size-lg text-white" href="/">
						<span class="text-white-75">EPB</span>
					</a>
					<a class="font-w600 smini-hidden w-100 font-size-lg text-white" href="/">
						<img style="max-height: 60px;" class=" img-fluid my-auto mx-auto d-block py-1"
							 :src="config.logo">
					</a>
					<!-- END Logo --><!-- Options -->
					<div>
						<!-- Close Sidebar, Visible only on mobile screens -->
						<a class="text-white ml-2" @click.prevent="showmenu=false">
							<i class="fa fa-times-circle"></i>
						</a>
						<!-- END Close Sidebar -->
					</div>
					<!-- END Options -->
				</div>
			</div>
			<!-- END Side Header -->
			
			<!-- Side Navigation -->
			<div class="content-side content-side-full d-flex justify-content-center flex-column" style="height:92%">
				<div class="d-flex">
					<div class="col flex-fill">
						{{config.user? config.user.username : ''}}<br>
						{{config.user? config.user.firm : ''}}
					</div>
				</div>
				<ul class="nav-main mt-2 flex-fill">
					<li class="nav-main-item">
						<router-link class="nav-main-link" to="/" @click.native="showmenu=false">
							<span class="nav-main-link-name">Home</span>
						</router-link>
					</li>
					<li class="nav-main-item">
						<router-link class="nav-main-link" to="/logs" @click.native="showmenu=false">Logs
						</router-link>
					</li>
					<li class="nav-main-item">
						<a class="nav-main-link" href="#" @click.prevent="logout">{{$t('Log out')}}
						</a>
					</li>
				</ul>
				<div>
					Version : {{version}}
				</div>
			</div>
			
			
			<!-- END Side Navigation -->
		</nav>
		
		<!-- END Sidebar -->
		
		<!-- Header -->
		<header id="page-header">
			<!-- Header Content -->
			<div class="content-header">
				<!-- Toggle Sidebar -->
				<!-- Layout API, functionality initialized in Template._uiApiLayout()-->
				<button type="button" class="btn d-lg-none btn-dual mr-3" @click.prevent="showmenu=true"
						v-if="backOrMenu()=='menu'">
					<i class="fa fa-fw fa-bars"></i>
				</button>
				<button type="button" class="btn d-lg-none btn-dual mr-3" @click.prevent="back()" v-else>
					<i class="fa fa-arrow-left"></i>
				</button>
				<!-- END Toggle Sidebar -->
				<h1 class="text-white flex-fill font-size-h4 font-w400 mb-0">
					{{$route.meta.title}}
				</h1>
				<div class="font-size-xs">{{config.user? config.user.username : ''}}<br><span>{{config.user? config.user.firm : ''}}</span>
				</div>
			</div>
			<!-- END Header Content -->
		
		</header>
		<!-- END Header -->
		
		<!-- Main Container -->
		<div class="container-fluid mb-5 h-100">
			<main id="main-container" class="h-100">
				<div class="content h-100 p-0 pt-3">
					<transition-page class="h-100" name="slide" mode="out-in">
						<router-view/>
					</transition-page>
				</div>
			</main>
		</div>
		<toast position="s"></toast>
	</div>
</template>

<script>
	import version from '@/services/version'
	import TransitionPage from '@/components/TransitionPage'
	import {mapActions, mapGetters} from 'vuex'
	import { Toast } from 'vuex-toast'
	import { ADD_TOAST_MESSAGE } from 'vuex-toast'
	import * as firebase from "firebase/app";
	import "firebase/messaging";

	export default {
		name: 'LayoutDefault',
		data: function () {
			return {
				version: version.version,
				showmenu: false,
			}
		},
		components: {
			TransitionPage, Toast
		},
		
		methods: {
			...mapActions('login', ['logout']),
			...mapActions('config', ['pushregistration']),
			...mapActions({
			  addToast: ADD_TOAST_MESSAGE
			}),
			backOrMenu: function () {
				switch (this.$route.name) {
					case 'logs':
					case 'home':
						return 'menu';
					case 'event':
					default:
						return 'back';
				}
			},
			back: function () {
				if (this.$route.name == 'event') {
					this.$router.push({name: 'home'});
				} else if (this.$route.name == 'resultMission') {
					if (this.$route.params.page && this.$route.params.page > 1) {
						this.$router.push({name: this.$route.name, params: {action:this.$route.params.action, page: Number(this.$route.params.page)-1, eventid: this.$route.params.eventid}});
					} else {
						this.$router.push({name: 'event', eventid: this.$route.params.eventid});
					}
				} else {
					this.$router.go(-1);
				}
			},
			getMessagingToken() {
				let self = this;
				this.$messaging.getToken().then(async (token) => {
					if (token) {
						const currentMessageToken = self.registertoken;
						console.log('token will be updated', currentMessageToken != token)
						if (currentMessageToken != token) {
							await this.pushregistration(token)
						}
					} else {
						console.log('No Instance ID token available. Request permission to generate one.');
						this.notificationsPermisionRequest();
					}
				}).catch(function (err) {
					console.log('An error occurred while retrieving token. ', err);
				});
			},
			notificationsPermisionRequest() {
				this.$messaging.requestPermission()
					.then(() => {
						this.getMessagingToken();
					})
					.catch((err) => {
						console.log('Unable to get permission to notify.', err);
					});
			},
			listenTokenRefresh() {
				const currentMessageToken = this.registertoken;
				console.log('currentMessageToken', currentMessageToken);
				if (!!currentMessageToken) {
					this.$messaging.onTokenRefresh(function () {
						this.$messaging.getToken().then(function (token) {
							this.pushregistration(token);
						}).catch(function (err) {
							console.log('Unable to retrieve refreshed token ', err);
						});
					});
				}
			},
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('config', {'config': 'getConfig', 'registertoken':'getRegisterToken'}),
		},
		mounted: function(){
			let self = this;
			navigator.serviceWorker
					.register('/service-worker.js')
					.then( (registration) => {
						self.$messaging = firebase.messaging();
						self.$messaging.useServiceWorker(registration);
						self.$messaging.usePublicVapidKey("BHUbK0BBmVNbXsxLybBnbeosux3NrV3j0gyQhl79oYTzegDEZtH-qX9IN2nzMupzPSzieiuFSVPWTaACMkX3u8Q"); // 1. Generate a new key pair
						self.notificationsPermisionRequest();
						self.listenTokenRefresh();

					});

			let listener = new BroadcastChannel('listener');
			// réception d'une notif
			listener.onmessage = function(e) {
				console.log('listener onmessage' , e);
				if (e.data.type =='message') {
					self.addToast({
						text: e.data.data.title,
						type: 'success',
						dismissAfter: 10000
					});
					// refresh event
					self.$store.dispatch('events/refresh');
				} else if(e.data.type =='notificationclick'){
				
				}
			};
		},
	};
</script>