import api from '@/services/api'

const SYNC_RUNNING = 2;
const SYNC_FINISH = 1;
const SYNC_WAITING = 0;


const store = {
	namespaced: true,
	state: {
		interventions: {}
	},
	mutations: {
		setintervention(state, {uid, data}) {
			console.log('setintervention', uid, data);
			state.interventions[uid] = data
		},
		reset(state) {
			state.interventions = {};
		},
		changesync(state, {uid, sync}) {
			if (state.interventions[uid]){
				state.interventions[uid].sync = sync;
			}
		},
		remove(state, uid){
			let inter = {...state.interventions};
			delete inter[uid];
			state.interventions = inter;
		}
	},
	
	actions: {
		setIntervention: ({commit, state}, {uid, data}) => {
			commit('setintervention', {uid, data});
		},
		finishIntervention: async ({commit, state, dispatch}, {uid}) => {
			try {
				commit('changesync', {uid:uid, sync: SYNC_RUNNING});
				let call = await api.post({
					url: 'missions/' + uid + '/save',
					params: state.interventions[uid]
				}).then(res => {
					if (res.response.uid) {
						commit('changesync', {uid:uid, sync: SYNC_FINISH});
					} else {
						commit('changesync', {uid:uid, sync: SYNC_WAITING});
					}
				}).catch(err => {
					commit('changesync', {uid:uid, sync: SYNC_WAITING});
				}).finally(async () => {
					dispatch('pictures/sync', {}, {root:true});
				})
			} catch (err) {
				console.log('finishIntervention', err);
				commit('changesync', {uid:uid, sync: SYNC_WAITING});
			}
		},
		pushAll: ({commit, state, getters, dispatch}) => {
			getters.tosyncs.forEach((item) => {
				dispatch('finishIntervention', {uid: item.uid});
			})
		},
		reset: ({commit, state}) => {
			commit('reset');
		},
		
		async delete(context, uid) {
			console.log('delete inter', uid);
			context.commit('remove', uid);
		},
		
		async removeSynced(context, evtids){
			console.log('removeSynced interventions');
			let inter = context.state.interventions;
			if (Object.keys(inter).length){
				_.forEach(inter, (i,uid) => {
					if (i.sync == SYNC_FINISH && evtids.indexOf(uid) === -1) {
						context.commit('remove', uid);
					}
				})
			}
		},
	},
	getters: {
		getAll: (state) => {
			return state.interventions;
		},
		getIntervention: (state) => (uid) => {
			return state.interventions[uid] ? state.interventions[uid] : {
				form_id: null,
				duration: 0,
				uid: uid,
				sync: null,
				syncfinished: false,
				geoloc: {latitude: null, longitude: null, accuracy: null},
				action: {status: '', name: ''}
			};
		},
		getSyncStatus: (state) => (uid) => {
			if (!state.interventions[uid]) {
				return null;
			}
			return state.interventions[uid].sync
		},
		tosyncs: (state) => {
			return Object.values(state.interventions).filter((item) => {
				return item.sync == SYNC_WAITING;
			})
		}
		
	},
}

export default store
