<template>
	<div>
		<template v-for="(events, date) in getEvents(period)">
			<ul class="list-group mb-3">
				<div class="font-size-h3 mb-2">{{date | formatDate}}</div>
				<template v-for="event in events">
					<li @click="clickEvent(event)"
						:key="event.uid" class="list-group-item shadow"
						:style="{'border-left-color': event.color}">
						<div class="d-flex justify-content-between">
							<div><span class="font-weight-bold">{{event.start | formatTime}}</span> ({{event.duration}})
							</div>
							<div class="font-size-sm">
								<span>{{$t(event.state)}}</span>
								<span class="badge badge-pill ml-2" :class="{'badge-danger': getSyncStatus(event.uid)===0, 'badge-success': getSyncStatus(event.uid)===1, 'badge-warning': getSyncStatus(event.uid)===2}">&NoBreak;</span>
							</div>
						</div>
						<div v-if="event.client.name">
							{{event.client.name}}
						</div>
						<div>
							{{event.title}}
						</div>
					</li>
					<div v-if="event.showactions" class=" mx-5">
						<router-link :to="{name: 'event', params:{eventid: event.uid}}" @click.native="setShowactions({uid: event.uid, value: false})" class="btn btn-block btn-primary">
							<i class="far fa-eye text-white"></i>&nbsp;{{$t('Detail')}}
						</router-link>
						<router-link :to="{name: 'resultMission', params:{action:action, eventid: event.uid}}" @click.native="setShowactions({uid: event.uid, value: false})" v-for="(action, k) in event.actions" :key="k" class="btn btn-block btn-info text-capitalize">
							{{action.name}}
						</router-link>
					</div>
				</template>
			
			
			</ul>
		</template>
	</div>
</template>

<script>
	import {mapGetters, mapActions} from 'vuex';
	
	export default {
		name: 'ListEvents',
		props: ['period'],
		data: () => {
			return {
				sheet: false
			};
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('events', [
				'getEvents',
			]),
			...mapGetters('interventions', [
				'getSyncStatus',
			])
		},
		mounted: async function(){
			await this.$store.dispatch('events/refresh')
		},
		methods: {
			...mapActions('events', ['setShowactions']),
			clickEvent: function (event) {
				console.log(event.actions);
				if (!event.actions || event.actions.length == 0) {
					this.$router.push({name: 'event', params: {eventid: event.uid}});
				} else {
					event.showactions = !event.showactions;
					this.setShowactions({uid: event.uid, value: event.showactions});
				}
			}
		}
	};
</script>

<style>
	.list-group a.list-group-item {
		color: inherit;
	}
	
	.list-group .list-group-item {
		border: none;
		border-left: 1px solid black;
		border-left-width: 10px;
		border-radius: 0;
		margin-bottom: 5px;
	}
</style>
