import Vue from 'vue'
import Vuex from 'vuex'
import VuexPersistence from 'vuex-persist'
import { createModule } from 'vuex-toast'

// If you want to use the default style (with webpack css-loader)
import 'vuex-toast/dist/vuex-toast.css'

import login from './login'
import events from './events'
import config from './config'
import forms from './forms'
import interventions from './interventions'
import pictures from './pictures'

Vue.use(Vuex)

const vuexLocal = new VuexPersistence({
	storage: window.localStorage,
	modules: ['login', 'events', 'forms', 'config', 'interventions']
});

export default new Vuex.Store({
	modules: {
		login, events, config, forms, interventions, pictures,
		toast: createModule({
			dismissInterval: 8000
		})
	},
	plugins: [vuexLocal.plugin]
})
