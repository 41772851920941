import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '@/views/Home.vue'
import Login from '@/views/Login.vue'
import Event from '@/views/Event.vue'
import ResultMission from '@/views/ResultMission.vue'
import store from '@/store/index';

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'login',
    component: Login,
    meta: {
      title: 'Login',
      logLayout: true,
    },
  },
  {
    path: '/',
    name: 'home',
    meta: {
      title: 'Accueil',
      logLayout: false,
    },
    component: Home
  },
  {
    path: '/event/:eventid',
    name: 'event',
    meta: {
      title: 'Intervention',
      logLayout: false,
    },
    component: Event,
    props: true
  },
  {
    path: '/resultMission/:eventid/:page?',
    name: 'resultMission',
    component: ResultMission,
    props: true,
    meta: {
      logLayout: false
    }
  },
  {
    path: '/logs',
    name: 'logs',
    meta: {
      title: 'Logs',
      logLayout: false,
    },
    component: () => import(/* webpackChunkName: "logs" */ '@/views/Logs.vue')

  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

const waitForStorageToBeReady = async (to, from, next) => {
  console.log('waitForStorageToBeReady');
  await store.restored
  next()
}
router.beforeEach(waitForStorageToBeReady)


router.beforeEach((to, from, next) => {
  const token = store.getters['login/getToken'];
  console.log('token', token, to.name);

  if (token === null && to.name !== 'login') {
      next({'name': 'login', replace: true});
  }
  else if (to.name === 'login' && token !== null ) {
       next({'name': 'home', replace: true});
  }else{
    next();
  }
});

export default router
