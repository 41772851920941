<template>
	<div class="d-flex flex-column h-100"
         v-touch:swipe.left.stop.prevent="nextPage" v-touch:swipe.right.stop.prevent="prevPage">
        <div class="flex-grow-1">
            <div class="row" v-for="question in getQuestion()" :key="'form-'+question.Name">
                <div class="col">
                    <div class="font-size-h1" v-if="question.Type=='h1'">{{question.Value}}</div>
                    <div class="font-size-h2" v-else-if="question.Type=='h2'">{{question.Value}}</div>
                    <div class="font-size-h3" v-else-if="question.Type=='h3'">{{question.Value}}</div>
                    <div class="font-size-h4" v-else-if="question.Type=='h4'">{{question.Value}}</div>
                    <div class="font-size-h5" v-else-if="question.Type=='h5'">{{question.Value}}</div>
                    <div class="font-size-h6" v-else-if="question.Type=='h6'">{{question.Value}}</div>
                    <p v-else-if="question.Type=='paragraph'" v-html="$options.filters.nl2br(question.Value)"></p>

                    <pictures :key="'picture-'+question.Name" v-else-if="question.Type=='picture'" :uid="eventid" :name="question.Name" :label="question.Label" class="my-2">PICTURESS</pictures>

                    <div class="row mb-3 mt-2" v-else-if="question.Type=='signature'" :key="'sign-'+question.Name">
                        <div class="col">
                            <label class="d-block" >{{question.Label}}</label>
                            <div class="row">
                                <div class="col" v-touch:swipe.stop.prevent="">
                                    <VueSignaturePad :key="question.Name" :name="question.Name" :options="signaturePadOptions"
                                                     class="signature_pad border border-2x" :ref="question.Name"/>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col">
                                    <div class="btn btn-danger float-right" @click="clearSignature(question.Name)">
                                        {{$t('Erase')}}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>


                    <b-form-group v-else-if="question.Type=='text'||question.Type=='email'||question.Type=='time'" class="my-2"
                                   :key="'input-'+question.Name"
                                  :class="{required:question.Required==1}"
                                  :label="question.Label" :label-for="question.Name">
						<b-input-group>
                            <b-form-input :id="question.Name" :type="question.Type" :class="{'is-invalid':errors[question.Name]}"
                                          :required="question.Required==1" v-model="intervention[question.Name]"
                            ></b-form-input>
                            <b-input-group-append v-if="question.Type=='time'">
                                <button class="btn btn-outline-secondary" type="button" @click.prevent="setTimeNow(question.Name)">Maintenant</button>
                            </b-input-group-append>
                        </b-input-group>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </b-form-group>

                    <b-form-group v-else-if="question.Type=='select'" class="my-2" :class="{required:question.Required==1}"
                                   :key="'select-'+question.Name"
                                  :label="question.Label" :label-for="question.Name">
                        <b-form-select :name="question.Name" :id="question.Name" v-model="intervention[question.Name]" :class="{'is-invalid':errors[question.Name]}">
                            <option v-for="opt in question.Option" :value="opt.key">{{opt.value}}</option>
                        </b-form-select>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </b-form-group>


                    <div class="form-group my-2" :class="{required:question.Required==1}" :key="'textarea-'+question.Name"
                         v-else-if="question.Type=='textarea'">
                        <label :for="question.Name">{{question.Label}}</label>
                        <textarea class="form-control" :required="question.Required==1" v-model="intervention[question.Name]"
                                   :class="{'is-invalid':errors[question.Name]}" :name="question.Name" :id="question.Name"></textarea>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </div>

                    <div class="form-group my-2" :class="{required:question.Required==1}" :key="'multi-'+question.Name"
                         v-else-if="question.Type=='multiselect'">
                        <label :for="question.Name">{{question.Label}}</label>
                        <select :name="question.Name" :id="question.Name" class="form-control" :required="question.Required==1"
                                 :class="{'is-invalid':errors[question.Name]}" multiple v-model="intervention[question.Name]">
                            <option v-for="opt in question.Option" :value="opt.key">{{opt.value}}</option>
                        </select>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </div>
                    <b-form-group :key="'checkbox-'+question.Name" v-else-if="question.Type=='checkbox'" class="my-2">
                        <b-form-checkbox size="lg" v-model="intervention[question.Name]" switch
                                          :class="{'is-invalid':errors[question.Name]}" class="custom-control-lg">
                            {{question.Label}}
                        </b-form-checkbox>
                        <div v-if="errors[question.Name]" class="invalid-feedback">{{errors[question.Name]}}</div>
                    </b-form-group>
                </div>
            </div>

            <div class="row mt-4">
                <div class="col">
                    <div class="btn btn-block btn-primary" @click="nextPage()">
                        {{$t(page==totalPages ? 'Submit' : 'Next page')}}
                    </div>
                </div>
            </div>
        </div>
	</div>
</template>


<script>
	
	// @ is an alias to /src
	import {mapActions, mapGetters} from 'vuex'
	import VueSignaturePad from 'vue-signature-pad';
	import Pictures from '@/components/Pictures.vue';
	
	export default {
		name: 'event',
		props: {
			'eventid': Number|String, 'action': Object,
			'page': {
				default: 1
			}
		},
		components: {VueSignaturePad, Pictures},
		created: async function () {
			if (!this.action) {
				this.$router.push({name: 'event', params: {eventid: this.eventid}});
				return;
			}
			
			this.$route.meta.title = this.$t('Intervention resume');
			
			let form = this.getform(this.action.form_id);
			
			if (form === null) {
				await this.$store.dispatch('forms/refresh', [this.action.form_id]);
				form = this.getform(this.action.form_id);
			}
			this.form = form;
			
			this.totalPages = Math.max.apply(Math, this.form.map(function (o) {
				return Number(o.Page);
			}));
			
			this.intervention = this.getIntervention(this.eventid);
			if (!this.intervention.form_id) {
				this.intervention.form_id = this.action.form_id;
			}
			if (!this.intervention.action.status) {
				this.intervention.action = this.action;
			}
			this.getGeoloc();
		},
		updated: async function () {
			let self = this;
			console.log('updated', this.action, this.form);
			if (this.action) {
				this.form.map(item => {
					if (item.Page == self.page && item.Type == 'signature') {
						console.log(self.intervention[item.Name]);
						if(self.$refs[item.Name]) {
							self.$refs[item.Name][0].fromDataURL(self.intervention[item.Name]);
						}
					}
					if (item.Type == 'multiselect'){
						if(self.intervention[item.Name] == undefined){
							self.intervention[item.Name] = [];
						}
					}
				});
			}
		},
		data: function () {
			let self = this;
			return {
				form: [],
				errors: {},
				intervention: {},
				totalPages: 1,
				signaturePadOptions: { // Check out https://github.com/szimek/signature_pad
					'minWidth': 2,
					'onEnd': function(e){
						self.saveAllSignatures();
					}
				}
			}
		},
		computed: {
			// mix the getters into computed with object spread operator
			...mapGetters('forms', [
				'getform'
			]),
			...mapGetters('interventions', [
				'getIntervention'
			]),
		},
		methods: {
			...mapActions('interventions', ['setIntervention', 'finishIntervention']),
            setTimeNow(questionname){
                let now = (new Date()).toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'});
			    if (undefined == this.intervention[questionname]) {
                    let resp = {...this.intervention};

                    resp[questionname] = now;
                    this.intervention = resp;
                } else {
			        this.intervention[questionname] = now;
                }
            },
			getQuestion: function () {
				let self = this;
				return this.form.filter(item => {
					return item.Page == self.page;
				});
			},
			checkEmail: function(email) {
				let re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  				return re.test(email);
			},
			nextPage: function () {
				let self = this;
				self.errors = {};
				// enregistrement des signatures s'il y en a
				this.form.map(item => {
					if (item.Page == self.page) {
						if (item.Type == 'signature') {
							self.saveSignature(item.Name);
						} else if (item.Type == 'email' && self.intervention[item.Name]) {
							// check de l'email s'il est complété
							if (!self.checkEmail(self.intervention[item.Name])) {
								self.errors[item.Name] = self.$t('Invalid email');
							}
						}
						if (item.Required && !self.intervention[item.Name]){
							self.errors[item.Name] = self.$t('Required input');
						}
					}
				});
				
				if (Object.keys(self.errors).length > 0) {
					return ;
				}
				
				if (this.page == this.totalPages) {
					this.intervention.syncfinished = true;
					this.setIntervention({uid: this.eventid, data: this.intervention});
					this.finishIntervention({uid: this.eventid});
					this.$router.push({name: 'home'});
				} else {
					this.setIntervention({uid: this.eventid, data: this.intervention});
					console.log('nextpage');
					this.$router.push({
						'name': 'resultMission',
						'params': {eventid: this.eventid, 'action': self.action, 'page': Number(this.page) + 1}
					});
				}
			},

            prevPage: function(){
                this.setIntervention({uid: this.eventid, data: this.intervention});
                this.finishIntervention({uid: this.eventid});
			    if (this.page == 1) {
					// retourne sur la fiche intervention
					this.$router.push({name: 'event', params: {eventid: this.eventid}});
				} else {
			        this.$router.push({
						'name': 'resultMission',
						'params': {eventid: this.eventid, 'action': self.action, 'page': Number(this.page) - 1}
					});
                }
            },
			saveAllSignatures: function(){
				let self = this;
				this.form.map(item => {
					if (item.Page == self.page) {
						if (item.Type == 'signature') {
							self.saveSignature(item.Name);
						}
					}
				});
			},
			clearSignature: function (name) {
				this.$refs[name][0].clearSignature();
			},
			saveSignature: function (name) {
				let sign = this.$refs[name][0].saveSignature();
				this.intervention[name] = sign.data;
			},
			getGeoloc: function(){
				let self = this;
				if (navigator.geolocation) {
					navigator.geolocation.getCurrentPosition(function (position) {
						self.intervention.geoloc.latitude = position.coords.latitude;
						self.intervention.geoloc.longitude = position.coords.longitude;
						self.intervention.geoloc.accuracy = position.coords.accuracy;
					});
				}
			}
		}
	}
</script>
